import Radium from 'radium';

/**
 * Example:
 * <CenterTextDiv 
    centerHeaderContent={AlbumText.albums[0].Name}
    sideHeaderContent={etc}
    textContent={AlbumText.albums[0].Description}
    />
 */

 var styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  centerHeaderContent: {
    textAlign: 'center',
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
  sideHeaderContent: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  textContent: {
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const CenterTextDiv = ({
  centerHeaderContent = null, 
  centerHeaderContentStyle = null,
  sideHeaderContent = null,
  sideHeaderContentStyle = null,
  textContent = null,
  textContentStyle = null,
  style = null,
}) => {

  var centerHeader = null;
  var sideHeader = null;
  var text = null;

  if (centerHeaderContent){
    centerHeader =  <h1 style={[styles.centerHeaderContent, centerHeaderContentStyle]}>{centerHeaderContent}</h1>;
  }
  if (sideHeaderContent){
    sideHeader = <h2 style={[styles.sideHeaderContent, sideHeaderContentStyle]}>{sideHeaderContent}</h2>;
  }
  if (textContent && typeof(textContent) == 'string'){
    text = <p style={[styles.textContent, textContentStyle]}>{textContent}</p>;
  }
  if (textContent && typeof(textContent) != 'string'){
    text = <div style={[styles.textContent, textContentStyle]}>{textContent}</div>;
  }

  return (
    <div style={[styles.container, style]}>
      {centerHeader}
      {sideHeader}
      {text}
    </div>
  );
  
};

export default Radium(CenterTextDiv);