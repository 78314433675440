import React from "react";
import Constants from "./constants";
import Radium from 'radium';

/**
 * Example:
 * <CustomLink 
 *  of={'Click Me'}
 *  url={'url.com'}
 *  style={{color: 'black'}}
 *  hoverStyle={{color: 'white'}}
 * />
 */

var styles = {
  defaultHover: {
    color: Constants.colors.gray,
    marginLeft: 5,
  },
  default: {
    color: Constants.colors.white,
    marginLeft: 0,
  },
  all: {
    textDecoration: 'none',
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};

const CustomLink = ({url = null, of = null, style = styles.default, hoverStyle = styles.defaultHover}) => {
  return (
    <a 
      style={[styles.all, {':hover': hoverStyle}, style]} 
      href={url}
      target="_blank"
    >
      {of}
    </a>
  );
  
};

export default Radium(CustomLink);