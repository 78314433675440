import Constants from "./helper/constants";
import $ from 'jquery';
import CustomArrowLink from "./helper/customArrowLink";
import CenterTextDiv from "./helper/centerTextDiv";
import YouTube from "./helper/youtube";
import ProduceContent from './helper/functions';
import MediaQuery from 'react-responsive';
import { useEffect } from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { saveIframe, saveHomeData } from './redux/sitedataSlice';

import useIframeLoaded from './hooks/useIframeLoaded';


var styles = {
  homepage: {
    position: 'relative',
    width: '100%',
  },
  image: {
    width: '100%',
  },
  mobile: {
    homepage: {
      position: 'relative',
      width: '100%',
    },
  },
  newsCell: {
    opacity: 1, 
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    position: 'fixed', 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    paddingTop: Constants.headerHeight/2, 
    left: 100,
    zIndex: 2,
  },
  newsCellHide: {
    opacity: 0,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    position: 'fixed', 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    paddingTop: Constants.headerHeight/2, 
    left: 100,
    zIndex: 0,
  },
  newsBoxPre: {
    marginLeft: 20,
    opacity: 0,
    transition: 'all .3s ease-in',
    WebkitTransition: 'all .3s ease-in',
  },
  newsBoxPost: {
    marginRight: 0,
    opacity: 1,
    transition: 'all .3s ease-in',
    WebkitTransition: 'all .3s ease-in',
    transitionDelay: '.3s',
  },
  iconWrapper: {
    width: '100%',
    height: '100%',
    position: 'fixed', 
    display: 'flex', 
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 2,
  },
  iconWrapperHide: {
    width: '100%',
    height: '100%',
    position: 'fixed', 
    display: 'flex', 
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 0,
  },
  downIcon: {
    opacity: .5,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    color: Constants.colors.white,
    marginBottom: -10,
    cursor: 'pointer',
  },
  downIconHover: {
    opacity: .5,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    color: Constants.colors.red,
    marginBottom: -10,
    cursor: 'pointer',
  },
  downIconHide: {
    opacity: 0,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    color: Constants.colors.white,
    marginBottom: -10,
  },
  iconWrapperMobile: {
    width: '100%',
    position: 'absolute', 
    display: 'flex', 
    alignItems: 'flex-start',
    paddingTop: window.innerHeight - Constants.headerHeight*2,
    justifyContent: 'center',
    zIndex: 5,
  },
  downIconMobile: {
    opacity: .5,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    color: Constants.colors.white,
    marginBottom: Constants.headerHeight,
  },
  downIconHideMobile: {
    opacity: 0,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    color: Constants.colors.white,
    marginBottom: Constants.headerHeight,
  },
};



//this is the website for the instagram feed embed
//https://www.powr.io/plugins/social-feed/standalone?id=33091730& 

const homeKeys = {
  spreadsheetId: '1yxrFli5DUP2vhu-c2ESqiuVT3e9uxJ8NgZsGsOPnJ68',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

var downIconStyle = styles.downIcon;


const Home = () => {
  const iFrameState = useSelector((state) => state.sitedata.iFrame);
  const globalHomeData = useSelector((state) => state.sitedata.homeData);
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(0);
  const [homePage, setHomePage] = useState([]);
  const [pageYOffset, setPageYOffset] = useState(0);

  const isIframeLoaded = useIframeLoaded('instagram-embed-0');

  // insert instagram iframe in correct (grab it from app, and insert it in)
  useEffect(() => {
    var iFrame = document.getElementById('instagram-embed-0');
    var iFrameClone = null;

    if (iFrame && !document.getElementById('instagram-embed-moved')) {
      iFrameClone = iFrame.cloneNode(true);
      iFrameClone.id = 'instagram-embed-moved';
      document.getElementById('instagramEmbed') && document.getElementById('instagramEmbed').appendChild(iFrameClone);
    }
  }, [isIframeLoaded])

  useEffect(() => {
    setLoaded(true);

    $.get(
        'https://sheets.googleapis.com/v4/spreadsheets/'
        + homeKeys.spreadsheetId
        + '/values/A1:F100' 
        + '?key='
        + homeKeys.PublicApiKey,
       function(result) {
          setHomePage(result.values);
          dispatch(saveHomeData(result.values));
    });

  }, []); 

  function newsText(color) {
    var text = "";
    var textLink = "Home";

    if (homePage && homePage[1]) {
      text = homePage[5][1];
      textLink = homePage[6][1];

      if (!text) {
        return null;
      }
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CustomArrowLink 
            arrow
            of={text}
            url={textLink}
            style={{color: color}}
            hoverStyle={{color: Constants.colors.gray}}
          />
        </div>
      );
    }
    return null;
  }

  function newsTitle(color) {
    var title = "";

    if (homePage && homePage[1]) {
      title = homePage[7][1];

      if (!title) {
        return null;
      }
      return (
        <div style={{display: 'flex', justifyContent: 'center', color: color}}>
          <CenterTextDiv 
            centerHeaderContent={title}
          />
        </div>
      );
    }
    return null;
  }

  function newsImage() {
    var youtubeLink = "WTPMiUcHFCk";
    var picture = "";
    if (homePage[1]) {
      youtubeLink = homePage[1][1];
      picture = homePage[2][1];
      if (youtubeLink) {
        return (
          <YouTube url={youtubeLink}/>
        );
      }
      if (picture) {
        return (
          <img src={picture} style={styles.image}/>
        );
      }
      return null;
    }
    return null;
  }

  function newsCell()  {
    var width = homePage[3] && window.outerWidth > 1050 ? homePage[3][1] : 500; //FIX !!!

    return (
      <div style={pageYOffset < 30 ? styles.newsCell : styles.newsCellHide}>
        <div style={loaded ? styles.newsBoxPost : styles.newsBoxPre}>
          <div style={{position: 'relative', width: width}}>
            {newsTitle(Constants.colors.white)}
            <div style={{marginTop: -10}}></div>
            {newsImage()}
            <div style={{height: 10}}></div>
            {newsText(Constants.colors.white)}
          </div>  
        </div> 
      </div>
    );
  }

  // mobile=true for mobile site, false for desktop
  function news(isIframeLoaded)  {
    if (!homePage[1] & !globalHomeData) {
      return null;
    }
    
    //homeData
    var news = globalHomeData.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4], x[5], 800, isIframeLoaded))});
    var news = news.filter(function (x) {return x != null });

    return (
      <div style={{zIndex: 1, width: '100%', position: 'relative', backgroundColor: Constants.colors.white}}>
          {news}
      </div>
    );
  }


  function handleMouseOver() {
    downIconStyle = styles.downIconHover;
  }

  function handleMouseOut() {
    downIconStyle = styles.downIcon;
  }

  function handleMouseClick() {
    window.scrollTo({
      top: window.innerWidth*.65 - Constants.headerHeight - 160,
      left: 0, 
      behavior: 'smooth' 
    });
    
    setCounter(counter + 1);
    dispatch(saveIframe());
    console.log(counter, iFrameState);
  }

  useEffect(() => {
    window.onscroll = function (f) {  
      // called when the window is scrolled. 
      setPageYOffset(window.pageYOffset); 
    };
  })

  return (
    <div className="pageContainer" style={{minHeight: window.innerHeight - Constants.footerHeight}}>
      <MediaQuery minWidth={800}>
        <div style={window.pageYOffset < 30 ? styles.iconWrapper : styles.iconWrapperHide}>
          <i 
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut} 
            onClick={handleMouseClick}
            key={'down arrow'}
            className="fa fa-angle-down fa-5x" 
            style={window.pageYOffset < 30 ? downIconStyle : styles.downIconHide}
          ></i>
        </div>
        {newsCell()}
      </MediaQuery>

      <div style={{position: 'relative', width: '100%'}}>
        <div class={{iFrameState}} style={{display: 'flex', justifyContent: 'center'}}>
          {news(isIframeLoaded)}
        </div>
      </div>
      
    </div>
  );
};

export default Home;

