import AlbumText from '../data/AlbumsData';
import Constants from "./constants";
import CustomNavLink  from './customNavLink';
import Radium from "radium";
import React from 'react';

var styles = {
albumsContainer: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',  
    zIndex: 2,
    width: '100%',
    top: Constants.headerHeight,
    left: 0,
    backgroundColor: Constants.colors.white,
    paddingBottom: 8,
  },
  albumContainer: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 2,
    marginTop: 2,
    marginRight: 2,
    marginLeft: 2,
  },
  text: {
  	fontSize: '80%',
    fontFamily: Constants.texts.headerText,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    zIndex: 12,
    pointerEvents: 'none',
  },
  navLink: {
    border: '2px solid rgba(0, 0, 0, 0)', 
    borderRadius: Constants.albumBorderRadius,
    textDecoration: 'none', 
    width: Constants.albumCyclerMobileHeight, 
    color: 'rgba(0, 0, 0, 0)',
    marginRight: 5, 
    marginLeft: 5,
  },
};

function AlbumCyclerMobile({color = Constants.colors.red}) {
  
  function makeAlbumBox(image, name) {
    return (
      <div style={styles.albumContainer}>
        <img style={{width: '100%', borderRadius: Constants.albumBorderRadius/2, boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19)',}} src={image}/>    
      </div>
    );
  }

  return (
    <div style={styles.albumsContainer}>
      {AlbumText.albums.map(function(a){ 
      return (
        <CustomNavLink 
          of={makeAlbumBox(a.Image, a.Name)}
          to={'/Recordings/' + a.Page}
          style={styles.navLink}
          hoverStyle={styles.navLink}
          activeStyle={{marginRight: 5, marginLeft: 5, border: '2px solid ' + color, borderRadius: Constants.albumBorderRadius, textDecoration: 'none', width: Constants.albumCyclerMobileHeight, color: 'rgba(0, 0, 0, 0)', opacity: 1,}}
        />
      )})}
    </div>
  );
  
}

export default Radium(AlbumCyclerMobile);
