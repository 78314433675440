import Constants from "./helper/constants";
import $ from 'jquery';
import ProduceContent from './helper/functions';
import { useEffect } from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { saveComposingData } from './redux/sitedataSlice';

var styles = {
  compositions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: window.innerHeight - Constants.footerHeight,
  },
};

const compKeys = {
  spreadsheetId: '1YrhmXhmBKmRTpQBUNSpHeHUnBc0RzBFojkOcfqCfCUw',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

const Composing = () => {
  const composingDataGlobal = useSelector((state) => state.sitedata.composingData);
  const dispatch = useDispatch();

  const [compPage, setCompPage] = useState([]);

  useEffect(() => {
    if (composingDataGlobal) {
      return;
    }

    $.get(
      'https://sheets.googleapis.com/v4/spreadsheets/'
      + compKeys.spreadsheetId
      + '/values/A1:F100' 
      + '?key='
      + compKeys.PublicApiKey,
      function(result) {
        if (true) {
          setCompPage(result.values);
          dispatch(saveComposingData(result.values));
        }
    });
  }, []);

  function news()  {
    var news = null;

    if (composingDataGlobal) {
      news = composingDataGlobal.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4], x[5], 1000))});
    } else if (compPage & compPage[1]) {
      news = compPage.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4], x[5], 1000))});
    } else {
      return null;
    }
 
    var news = news.filter(function (x) {return x != null });
    return (
      <div style={{zIndex: 1, width: '100%', position: 'relative', backgroundColor: Constants.colors.white}}>
          {news}
      </div>
    );
  }

  return (
    <div style={styles.compositions}>            
      {news()}
    </div> 
  );
};

export default Composing;