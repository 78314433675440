import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * Example:
 * <YouTube url={'//www.youtube.com/embed/1IkJHJKzBrg'} style={{flex: '1 0 300px', position: 'relative'}}/>
 */

const Youtube = ({url = null}) => {
  // propTypes: {
  //   url: PropTypes.node.isRequired,
  //   style: PropTypes.object,
  // },

  return (
    <div style={{position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%', margin: '0px auto'}}>
      <LiteYouTubeEmbed
        id={url} // Default none, id of the video or playlist
        //poster="maxresdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
        title="YouTube Embed" // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
        noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
        //playerClass="youtubeplaybutton"
      />
    </div>
  );
};

export default Youtube;