/**
 * Example:
 * <BgImg src={'image.jpg'}/>
 */

var styles = {
  image: {
    width: '100%',
    marginBottom: -10,
  },
};

// TODO - remove this and use image loader for all background images

const BgImg = ({src = null, darken = 0}) => {
  return (
    <div style={{
      backgroundImage: 'linear-gradient( rgba(0, 0, 0, ' + darken + '), rgba(0, 0, 0, ' + darken + ') ), url("' + src + '")',
      position: 'fixed',
      zIndex: -1,
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      WebkitBackgroundSize: 'cover',
      MozBackgroundSize: 'cover',
      OBackgroundSize: 'cover',
      backgroundSize: 'cover'}}></div>
  );
};

export default BgImg;