import Constants from "../helper/constants";
import $ from 'jquery';
import ProduceContent from '../helper/functions';
import MediaQuery from 'react-responsive';
import { useEffect } from 'react';
import { useState } from 'react';

var styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: window.innerHeight - Constants.footerHeight,
    paddingTop: Constants.headerHeight + 20,
  },
  pageMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: window.innerHeight - Constants.footerHeight,
    paddingTop: 20,
  },
};

var pageKeys = {
  spreadsheetId: '15ChictvFx8qKFVGDpsWag9LWqSJSJ4_SgdbB88m1eXI',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

const MusicAllianceProject = () => {
  const [page, setPage] = useState([]);

  // content
  useEffect(() => {
    $.get(
        'https://sheets.googleapis.com/v4/spreadsheets/'
        + pageKeys.spreadsheetId
        + '/values/A1:F100' 
        + '?key='
        + pageKeys.PublicApiKey,
      function(result) {
      if (true) {
        setPage(result.values);
      }
    });
  }, []);

  function news()  {
    if (!page || !page[1]) {
      return null;
    }

    var news = page.map(function(x){return(ProduceContent(x[3], x[0], x[1], x[2], x[4], x[5], 800))});
    var news = news.filter(function (x) {return x != null });
    return (
      <div style={{zIndex: 1, width: '100%', position: 'relative', backgroundColor: Constants.colors.white}}>
          {news}
      </div>
    );
  }

  return (
    <>
      <MediaQuery minWidth={800}>
        <div style={styles.page}>            
          {news()}
        </div> 
      </MediaQuery>
      <MediaQuery maxWidth={800}>
        <div style={styles.pageMobile}>            
          {news()}
        </div> 
      </MediaQuery>
    </>
  );
};

export default MusicAllianceProject;