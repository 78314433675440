import Radium from 'radium';
import LazyLoad from 'react-lazyload';

/**
 * Example:
 * <Img src={'image.jpg'} style={{width: '100%'}}/>
 */

var Img = ({src = null, style = null, rootStyle = null}) => {

  // automatically get low res pic for backup
  var fileFormat = '.jpg';
  if (src.includes('.jpeg')) {
    var fileFormat = '.jpeg'
  }
  if (src.includes('.png')) {
    var fileFormat = '.png'
  }
  var source = src.split(fileFormat);

  return (
    <LazyLoad once={true} offset={200}  style={{lineHeight: 0}} placeholder={<img style={[{width: '100%'}, style]} src={source[0] + 'low.jpg'}/>}>
      <img src={src} style={[{width: '100%'}, rootStyle]} onError={(event) => event.target.style.display = 'none'}/>
    </LazyLoad>
  );
  
};

export default Radium(Img);