import Constants from './helper/constants';
import { useParams, Navigate } from "react-router-dom";
import AlbumText from './data/AlbumsData';
import CustomLink from './helper/customlink';
import CenterTextDiv from './helper/centerTextDiv';
import LinkText from './helper/linkText';
import MediaQuery from 'react-responsive';
import AlbumCycler from './helper/albumCycler';
import AlbumCyclerMobile from './helper/albumCyclerMobile';
import ProduceContent from './helper/functions';
import AlbumsContentHelper from './helper/albumsContentHelper';


var styles = {
  albumBox: {
    marginBottom: Constants.footerHeight,
    maxWidth: 800,
  },
  albumBoxPre: {
    marginBottom: Constants.headerHeight,
    maxWidth: 800,
    paddingRight: Constants.PAD,
    paddingLeft: Constants.PAD,
    opacity: 0,
  },
  cdCover: {
    flex: '1 0 250px',
    paddingBottom: Constants.PAD,
  },
  buyContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap', 
    paddingTop: Constants.PAD - 10,
    paddingBottom: Constants.PAD - 10,
  },
  buyContainerFixed: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'left',
    flexWrap: 'wrap', 
    position: 'fixed',
    height: '100%',
    marginLeft: 20,
    left: 0,
  },
};

function getAlbumData(pageName) {
  return(AlbumText.albums.find(element => element.Page == pageName));
}

function makeBuyAtCell(vendor, textColor) {
  var inside = 
    <div style={{display: 'flex', alignItems: 'center', color: textColor,}}>
      <i 
        key={'vendor icon'}
        className={vendor.Icon}
        style={{width: 30}}
      ></i>
      <span style={{paddingLeft: 5, fontSize: '90%'}}>{vendor.Name}</span>
    </div>;

  return (
    <div style={{display: 'flex'}}>
      <CustomLink 
        of={inside}
        url={vendor.Link}
        style={{
          position: 'relative', 
          opacity: .8,
          color: textColor,
          marginTop: 5, 
          marginBottom: 5}}
        hoverStyle={{
          opacity: .3}}
      />
    </div>
  );
}

function news(page, BackgroundColor)  {
  if (!page[1]) {
    return null;
  }

  var news = page.map(function(x){return(ProduceContent(x[3], x[0], BackgroundColor, x[2], x[4], x[5], 800))});
  var news = news.filter(function (x) {return x != null });
  return (
    <div style={{zIndex: 1, width: '100%', position: 'relative', backgroundColor: BackgroundColor}}>
        {news}
    </div>
  );
}

export default function Albums(props){
  
    const params = useParams();
    
    var album = getAlbumData(params.album);

    if (!album) {
      return (<Navigate to="/Recordings" replace />);
    }
    var textColor = parseInt(album.BackgroundColor.substr(1,2), 16) + parseInt(album.BackgroundColor.substr(3,2), 16) + parseInt(album.BackgroundColor.substr(5,2), 16) < 383 ? Constants.colors.white : Constants.colors.black;

    return (
      <div style={{backgroundColor: album.BackgroundColor, color: textColor, display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', minHeight: '100vh', width: '100%', marginBottom: -1 * Constants.footerHeight}}>
        <MediaQuery minWidth={1050}>
          <div style={styles.buyContainerFixed}>
            <hr style={{backgroundColor: textColor}}/>
            {album.BuyAt.map(x => makeBuyAtCell(x, textColor))}
            <hr style={{backgroundColor: textColor}}/>
          </div>
          <AlbumCycler color={album.AccentColor} page={album.Page}/>
        </MediaQuery>  
        <div style={styles.albumBox}>
          <MediaQuery minWidth={800}>
            <div style={{height: Constants.headerHeight + Constants.albumCyclerMobileHeight, width: '100%'}}></div>
          </MediaQuery>
          <MediaQuery maxWidth={800}>
            <div style={{height: Constants.headerHeight, width: '100%'}}></div>
            <AlbumCyclerMobile color={album.AccentColor} page={album.Page}/>
          </MediaQuery>
          <div style={{paddingRight: Constants.PAD, paddingLeft: Constants.PAD,}}>
            <CenterTextDiv 
              centerHeaderContent={album.Name}
              style={{paddingTop: 20}}
              textContent={<LinkText color={album.AccentColor} text={album.Subtitle}/>}
              textContentStyle={{textAlign: 'center'}}
            />
            <MediaQuery maxWidth={1050}>
              
              <hr style={{backgroundColor: textColor}}/>
              <div style={styles.buyContainer}>
                {album.BuyAt.map(x => makeBuyAtCell(x, textColor))}
              </div>
            </MediaQuery> 
            <hr style={{backgroundColor: textColor}}/>  
          </div>
          <div style={{display: 'flex', width: '100%', flexWrap: 'wrap'}}>
            <AlbumsContentHelper albumPage={album.Page}/>
          </div>
        </div> 
      </div>
    );
}