import Constants from './constants';
import CustomLink from './customlink';

var linkMap = {
  'The Music Alliance Project': '/MusicAllianceProject',
  'The Corn Knight': '/Recordings/TheCornKnight',
  'Turtle': '/Recordings/Turtle',
  'Gapi': '/Gapi',
  'En Com\u00FAn': '/Recordings/EnComun',
  'Berklee College of Music': 'https://college.berklee.edu/people/chase-morrin',
  'Yaniv Yacoby': 'http://yanivyacoby.com',
  'Kevin Jang': 'http://kevinjang.com',
  'Maddie Tucker': 'http://stringtheorycellos.com/maddie-tucker/',
  'Vijay Iyer': 'http://vijay-iyer.com/',
  'Bruce Brubaker': 'https://brucebrubaker.com/',
  'Jason Moran': 'http://www.jasonmoran.com/',
  'Kati Agocs': 'http://www.katiagocs.com/',
  'Ethan Heyenga': 'http://www.ethanheyenga.com/',
  'Danilo Pérez': 'http://www.daniloperez.com/',
  'DoYeon Kim': 'http://www.doyeonmusic.com/',
  'Burcu Gulec': 'https://www.instagram.com/bgulecmusic/?hl=en',
  'Naseem Alatrash': 'https://www.alatrashmusic.com/',
  'George Lernis' : 'https://lernismusic.com/home',
  'Chase Morrin' : 'https://chasemorrin.com/',
  'John Patitucci' : 'https://www.johnpatitucci.com/',
  'Farayi Malek' : 'https://www.farayimalek.com/',
  'Heiraza' : 'https://heiraza.com/',
};

const LinkText = ({text = null, color = null}) => {
  // propTypes: {
  //   text: PropTypes.string.isRequired,
  //   color: PropTypes.string,
  // },

  var finalArr = [];
  var re = new RegExp(Object.keys(linkMap).join('|'),"g");
  var textArr =text.split(re);
  var linkArr =text.match(re);
  var textColor =color ?color : Constants.colors.red;
  if (linkArr != null) {linkArr.reverse();}

  for (var x in textArr) { 
    finalArr.push(textArr[x]);
    if (linkArr == null || linkArr.length == 0) {break}
    var name = linkArr.pop();
    finalArr.push(<CustomLink 
      of={name} 
      url={linkMap[name]} 
      style={{color: textColor}} 
      hoverStyle={{color: Constants.colors.gray}}/>
    );
  }

  return (
    <span>{finalArr}</span>
  );
  
};

export default LinkText;