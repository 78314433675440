import Constants from "./constants";
import CustomArrowLink from "./customArrowLink";
import CenterTextDiv from "./centerTextDiv";
import YouTube from "./youtube";
import YouTubeMobile from "./youtubeMobile";
import InnerHTML from "dangerously-set-html-content";
import Img from './Img';
import LinkText from './linkText';
import CustomLink from './customlink';
import React from 'react';
import ReactPlayer from "react-player";



function createEmbedMarkup(embedScript) {
  return {
    __html:
    embedScript
  };
}

var styles = {
  videoInfoCell: {
    flex: '1 0 300px',
    paddingRight: 20,
    paddingBottom: 10,
  },
}

export default function ProduceContent(style, content, backgroundColor, extraContent, platform, videoDescription, pagewidth, isIframeLoaded = false) {

    var mobile = window.innerWidth < 800 ? true : false;
    if ((platform == 'desktop' && mobile) || (platform == 'mobile' && !mobile)) {
      return null;
    }

    var maxWidth = mobile ? 'none' : 800;
    var maxWidth = pagewidth ? pagewidth : maxWidth;

    var backgroundColor = backgroundColor ? backgroundColor : Constants.colors.transparent;
    backgroundColor = backgroundColor == "black" ? Constants.colors.black : backgroundColor;
    var textColor = parseInt(backgroundColor.substr(1,2), 16) + parseInt(backgroundColor.substr(3,2), 16) + parseInt(backgroundColor.substr(5,2), 16) < 383 ? Constants.colors.white : Constants.colors.black;
    if (style == "title") { 
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor, color: textColor}}>
          <div style={{maxWidth: maxWidth, width: '100%'}}>
            <div style={{backgroundColor: backgroundColor}}></div>
            <CenterTextDiv 
              centerHeaderContent={content}
              sideHeaderContent={extraContent == 'nohr' ? null : extraContent}
            />
            {extraContent ? null : <hr style={{backgroundColor: textColor}}/>}
          </div>
        </div>
      );
    }
    if (style == "soundcloud") { 
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor, color: textColor}}>
          <div style={{maxWidth: maxWidth, width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{backgroundColor: backgroundColor}}></div>
            <ReactPlayer
              url="https://soundcloud.com/user-565646074/sets/ize-trio-the-global-suites"
            />
          </div>
        </div>
      );
    }
    if (style == "subtitle") { 
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor, color: textColor, fontSize: '80%'}}>
          <div style={{maxWidth: maxWidth, width: '100%'}}>
            <div style={{backgroundColor: backgroundColor}}></div>
            <CenterTextDiv 
              centerHeaderContent={content}
              sideHeaderContent={extraContent == 'nohr' ? null : extraContent}
            />
            {extraContent ? null : <hr style={{backgroundColor: textColor}}/>}
          </div>
        </div>
      );
    }
    if (style == "multibox") { 
      var maxImgWidth = maxWidth;
      if (extraContent) {
        maxImgWidth = extraContent;
      }      
      var cont = content.replace(" ","");
      cont = cont.split("$");
      cont = cont.map(function(x){return(<div style={{flex: '1 0 100px', paddingLeft: 15, paddingRight: 15}}><CenterTextDiv textContent={<LinkText color={accentColor} text={x}/>}/></div>)})

      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', maxWidth: maxImgWidth + 'px', gap: space + 'px'}}>
            {cont}
          </div>
        </div>
      );
    }
    if (style == "hr") { 
      var maxImgWidth = maxWidth;
      if (extraContent) {
        maxImgWidth = extraContent;
      }
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor, color: textColor}}>
          <div style={{maxWidth: maxImgWidth, width: '100%'}}>
              <hr style={{backgroundColor: textColor}}/>
          </div>
        </div>
      );
    }
    if (style == "link") {
      return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: 10, paddingTop: 10, backgroundColor: backgroundColor}}>
          <div style={{maxWidth: maxWidth, marginRight: Constants.PAD, marginLeft: Constants.PAD}}>
            <CustomArrowLink 
              arrow
              of={content}
              url={extraContent}
              style={{color: textColor}}
              hoverStyle={{color: Constants.colors.gray}}
            />
          </div>
        </div>
      );
    }
    if (style == "presslink") {
      return (
        <div>
          <CustomLink 
            of={content} // name
            url={extraContent} // link
          />
          <br/>
          <br/>
        </div>
      );
    }
    if (style == "text") {
       var accentColor = extraContent ? extraContent : null;
       return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor, color: textColor}}>
          <div style={{width: maxWidth}}>
            <CenterTextDiv 
              textContent={<LinkText color={accentColor} text={content}/>}
            />
          </div>
        </div>
      );
    }
    if (style == 'simpletext') {
      return ( 
      <p>
        <LinkText text={content} style={{color: textColor}} color={accentColor}/>
      </p>
      );
    }
    if (style == 'textwithimage') {
      var imageCont = extraContent.replace(" ","");
      imageCont = imageCont.split(",");

      var image = null;
      if (imageCont[1]) {
        image = <CustomArrowLink of={<Img src={imageCont[0]}/>} url={imageCont[1]}/>;
      }
      if (!imageCont[1]) {
        image = <Img src={imageCont[0]}/>;
      }
  
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{maxWidth: maxWidth, display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap'}}>
          <div style={{flex: '100 1 250px', paddingRight: 10}}>
            <CenterTextDiv 
              sideHeaderContent={content}
              sideHeaderContentStyle={{paddingTop: 0}}
              style={{paddingTop: 0, paddingBottom: 20}}
              textContent={videoDescription}
            />
          </div>
          <div style={{flex: '1 1 250px', position: 'relative', maxWidth: 250}}>           
            {image}
          </div>
          </div>
        </div>
      );
    }
    if (style == "image") {
      var maxImgWidth = extraContent ? extraContent : maxWidth;
      var padding = 0;
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: padding, paddingRight: padding, backgroundColor: backgroundColor}}>
          <div style={{flex: '1 0 auto', width: '100%', maxWidth: maxImgWidth + 'px'}}>
            <Img src={content}/>
          </div>
        </div>
      );
    }
    if (style == 'imageintext') {
      var floatWidthUrl = ['none', '100%', null];
      floatWidthUrl = extraContent.split(",");
      var float = floatWidthUrl[0] == 'right' ? [15, 0] : [0, 0];
      float = floatWidthUrl[0] == 'left' ? [0, 15] : [0, 0];
      // 3 parameters
      if (floatWidthUrl[2]) {
        return (
          <div style={{float: floatWidthUrl[0], width: floatWidthUrl[1], marginLeft: float[0], marginRight: float[1], marginBottom: 3}}>
            <CustomArrowLink of={<Img src={content}/>} url={floatWidthUrl[2]}/>
          </div>
        );
      }

      // 2 parameters
      if (floatWidthUrl[1]) {
        return (
          <div style={{float: floatWidthUrl[0], width: floatWidthUrl[1], marginLeft: float[0], marginRight: float[1], marginBottom: 3}}>
            <Img src={content}/>
          </div>
        );
      }
      
      // 1 parameter
      if (floatWidthUrl.length == 1 && floatWidthUrl[0]) {
        return (
          <CustomArrowLink of={<Img src={content}/>} url={floatWidthUrl[0]}/>
        );
      }
      
      // 0 parameters
      return (
      <div style={{maxWidth: '100%'}}>
        <Img src={content} />
      </div>
      );

    }
    if (style == "imagelink") {
      var padding = mobile ? 0 : Constants.PAD;
      var image = extraContent ? <CustomArrowLink of={<Img src={content}/>} url={extraContent}/> : <Img src={content}/>;
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: padding, paddingRight: padding, backgroundColor: backgroundColor}}>
          <div style={{width: '100%', maxWidth: maxWidth}}>
            {image}
          </div>
        </div>
      );
    }
    if (style == "images") {
      extraContent = extraContent.split(",");
      var maxImgWidth = maxWidth;
      var space = 0;
      if (extraContent[0]) {
        maxImgWidth = extraContent[0];
      }
      if (extraContent[1]) {
        space = extraContent[1];
      }
      
      var cont = content.replace(" ","");
      cont = cont.split(",");
      cont = cont.map(function(x){return(<div style={{flex: '1 0 100px'}}><Img src={x} style={{width: '100%'}}/></div>)})

      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', maxWidth: maxImgWidth + 'px', gap: space + 'px'}}>
            {cont}
          </div>
        </div>
      );
    }
    if (style == "youtube") {
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{maxWidth: maxWidth, width: '100%'}}>
            {mobile ? <YouTubeMobile url={content}/> : <YouTube url={content}/>}
          </div>
        </div>
      );
    }
    if (style == "space") {
      return (
        <div style={{backgroundColor: backgroundColor, height: content + 'px'}}></div>
      );
    }
    if (style == "card") {
      return (
        <div style={{borderBottomLeftRadius: Constants.albumBorderRadius, borderBottomRightRadius: Constants.albumBorderRadius, boxShadow: Constants.bottomCardShadow, height: content, backgroundColor: 'black'}}></div>
      );
    }
    if (style == "instagramPost") {
      var posts = content.split('$$');
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', maxWidth: maxWidth, flexWrap: 'wrap'}}>
            {posts.map(x => (<InnerHTML html={createEmbedMarkup(x).__html} />))}
          </div>
        </div>
      );
    }
    if (style == "instagramSocial") {
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: Constants.PAD, paddingRight: Constants.PAD, backgroundColor: backgroundColor}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: maxWidth}}>
          <div style={{width: '100%', marginBottom: -140}}>
            <div id={'instagram'}></div>
            {/* <InnerHTML html={createEmbedMarkup(content).__html} /> */}
          </div>
          <div style={{width: '100%', zIndex: 2, height: 140, backgroundColor: 'white'}}></div>
          </div>
        </div>
      );
    }
    if (style == "instagramProfile") {
//       const timerId = setInterval(() => {
//         const embedIsLoaded = document.getElementById('instagram-embed-0');
//         const embedExists = document.getElementById('instagramEmbed');
//         if (!embedIsLoaded && embedExists) {
//           const script = document.createElement('script');

//           script.src = "https://www.instagram.com/embed.js";
//           script.async = true;

//          // document.body.appendChild(script);

// //          console.log('reload inst');
//         }
//         if (embedIsLoaded) {
//            clearInterval(timerId);
//         }
       
//       }, 2000);

      var instaMaxWidth = window.innerWidth < 540 ? window.innerWidth : 540;
      return (
        <div style={{display: 'flex', justifyContent: 'center', backgroundColor: backgroundColor, maxWidth: window.innerWidth}}>
          <div id={'instagramEmbed'} style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', maxWidth: instaMaxWidth - 30}}>
            {/* <div __dangerouslysetinnerhtml={{ __html: iFrameClone.innerHTML }}></div> */} 
          </div>
        </div>
      );

      // if (isIframeLoaded) {
      //   // // get the iframe
      //   // var iFrame = document.getElementById('instagram-embed-0');
      //   // var iFrameClone = null;
      //   // if (iFrame) {
      //   //   iFrameClone = iFrame.cloneNode(true);
      //   //   iFrameClone.id = 'instagram-embed-moved';
      //   //   iFrame && iFrame.remove();
      //   //   console.log('asjkdfljaskldf');
      //   //   //document.getElementById('instagramEmbed').appendChild(iFrameClone);
      //   // }
      //   // if (!iFrameClone) {
      //   //   return null;
      //   // }
        
      //   // console.log('we have loaded and trying put in iframe', iFrameClone);
        
        
      // }
      // return null;

    }
    if (style == "videobox") {
      function handleDescription (x) {
        if (x.includes("LINK")) {
          x = x.split(" LINK ");
          return (
            <div style={{paddingTop: 15}}>
              <CustomArrowLink 
                arrow
                of={x[0]}
                url={x[1]}
                style={{color: textColor}}
                hoverStyle={{color: Constants.colors.gray}}
              />
            </div>
          );
        }
        return (<div style={{paddingTop: 15}}>{x}</div>);
      }

      var name = content;
      var url = extraContent; 
      var description = null;
      var link = null;
      if (videoDescription) {
        description = videoDescription.split(' $ ');
        description = description.map(handleDescription);
      }

      var videoCellStyle = {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap',
        borderRadius: Constants.albumBorderRadius,
        boxShadow: Constants.cardShadow,
        padding: Constants.PAD,
        width: '100%',
        maxWidth: maxWidth,
        backgroundColor: 'rgba(238, 238, 238, .1)',
      };

      if (mobile) {
        videoCellStyle.boxShadow = 0; //Constants.cardShadowBottom;
        videoCellStyle.paddingTop = 40;
        videoCellStyle.backgroundColor = null;
        videoCellStyle.backgroundColor = null;
      }
      
      return (
        <div style={{display: 'flex', justifyContent: 'center', backgroundColor: backgroundColor, color: textColor, width: '100%'}}>
          <div style={videoCellStyle}>
            <div style={styles.videoInfoCell}>
              {description ? <CenterTextDiv 
                sideHeaderContent={name}
                sideHeaderContentStyle={{padding: 0}}
                style={{padding: 0}}
                textContent={description}
                textContentStyle={mobile ? null : {paddingBottom: 0}}
              /> : null}
            </div>
            <div style={{flex: '1 0 300px', position: 'relative'}}>
              <YouTube url={url}/>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }