import Constants from "./constants";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

/**
 * Example:
 * <CustomNavLink 
 *  of={'Click Me'}
 *  to={'RouteID'}
 *  style={{color: 'black'}}
 *  hoverStyle={{color: 'white'}}
 *  activeStyle={{color: 'red'}}
 * />
 */

var styles = {
  defaultHover: {
    textDecoration: 'none',
    color: Constants.colors.white,
  },
  defaultActive: {
    textDecoration: 'none',
    color: Constants.colors.red,
  },
  default: {
    textDecoration: 'none',
    color: Constants.colors.black,
  },
  all: {
    textDecoration: 'none',
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};

const CustomNavLink = ({to = null, of = null, style = styles.default, hoverStyle = styles.defaultHover, activeStyle = styles.defaultActive}) => {
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseOver() {
    setIsHovering(true);
  }

  function handleMouseOut() {
    setIsHovering(false);
  }

  function navStyle(active) {
    if (active) {
      return (activeStyle);
    }
    if (isHovering) {
      return (hoverStyle)
    }
    else {
      return (style)
    }
  }

  return (
    <NavLink 
      key={'link'}
      to={to}
      style={({ isActive }) => {return (navStyle(isActive));}}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut} 
    >
      <div style={styles.all}>
        {of}
      </div>
    </NavLink>
  );
};

export default CustomNavLink;