import { createSlice } from '@reduxjs/toolkit'

export const sitedataSlice = createSlice({
  name: 'sitedata',
  initialState: {
    iFrame: 0,
    homeData: null,
    calendarData: null,
    composingData: null,
    recordingData: null,
    bioData: null,
    pressData: null,
  },
  reducers: {
    saveIframe: (state, action) => {
      state.iFrame = action.payload
    },
    saveHomeData: (state, action) => {
      state.homeData = action.payload
    },
    saveCalendarData: (state, action) => {
      state.calendarData = action.payload
    },
    saveComposingData: (state, action) => {
      state.composingData = action.payload
    },
    saveRecordingData: (state, action) => {
      state.recordingData = action.payload
    },
    saveBioData: (state, action) => {
      state.bioData = action.payload
    },
    savePressData: (state, action) => {
      state.pressData = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveIframe, saveHomeData, saveCalendarData, saveComposingData, saveRecordingData, saveBioData, savePressData } = sitedataSlice.actions

export default sitedataSlice.reducer