import {useState, useEffect} from "react";

const useIframeLoaded = (iframeId) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const iframe = document.getElementById(iframeId);

        if (!isLoaded) {
            iframe && iframe.addEventListener('load', () => setIsLoaded(true));
            return () => {iframe && iframe.removeEventListener('load', () => setIsLoaded(true))}
        }
        
    })

    return isLoaded;
}

export default useIframeLoaded;
